<template>
    <div>
        <div style="border: 1px solid #ececec; border-radius: 5px;">
            <div class="row flex-grow-0" style="padding: 0.5rem 1rem 0.5rem 1rem;">
                <span class="col-12" style="font-size: 14px; font-weight: bold;">
                    {{ $t("제어 스케줄 설정") }}
                </span>
            </div>
            <div v-if="eventDetail" class="row flex-grow-0" style="padding: 0.5rem 1rem 0.5rem 1rem;">
                <div class="col-6">
                    <label for="schdEventName">{{ $t("이벤트명") }}</label>
                    <!-- <input v-model="eventDetail.schdEventName" type="text" class="form-control" placeholder="이벤트명"
                        :disabled="!isEditMode"> -->
                    <valid-input
                        :vid="'이벤트명'"
                        :classList="'form-control'"
                        :inputType="'text'"
                        :inputValue.sync="eventDetail.schdEventName"
                        :placeholder="$t('이벤트명')"
                        :rules="rules.COMMON_NAME_RULE"
                        :errorMessage="validMessage.COMMON_NAME_VALID_MESSAGE"
                        :disabled="!isEditMode"
                    ></valid-input>
                </div>
                <div class="col-6">
                    <label for="schdHour">{{ $t("설정 시간") }}</label>
                    <date-picker
                        v-model="eventDetail.schdHour"
                        placeholder="설정 시간"
                        vertical
                        :config="{ format: 'HH:mm', locale: 'ko' }"
                        :disabled="!isEditMode"
                    ></date-picker>
                </div>
            </div>
            <div v-if="eventDetail" class="row flex-grow-0" style="padding: 0.5rem 1rem 0.5rem 1rem;">
                <div class="col-6">
                    <label for="equipType">설비 유형</label>
                    <select
                        v-model="eventDetail.equipType"
                        class="form-control"
                        id="equipType"
                        @change="changeEquipType(eventDetail.equipType)"
                        :disabled="!isEditMode"
                    >
                        <option :value="null">설비 유형 선택</option>
                        <option v-for="type in equipTypeList" :key="type.value" :value="type.value">
                            {{ type.text }}
                        </option>
                    </select>
                </div>
                <div class="col-6">
                    <label for="">제어 모드</label>
                    <select
                        v-model="eventDetail.ctrlMode"
                        class="form-control"
                        id="ctrlMode"
                        :disabled="eventDetail.equipType == null || !isEditMode"
                        @change="getProperty"
                    >
                        <option :value="null">제어 모드 선택</option>
                        <option v-for="(mode, i) in ctrlModeList" :key="i" :value="mode.ctrlMode">
                            {{ mode.ctrlModeName }}
                        </option>
                    </select>
                </div>
            </div>
            <div v-if="eventDetail" class="row flex-grow-0" style="padding: 0.5rem 1rem 0.5rem 1rem;">
                <div class="col-4">
                    <label for="repeatType">반복 유형</label>
                    <select
                        v-model="eventDetail.repeatType"
                        class="form-control"
                        id="repeatType"
                        @change="selectRepeatType"
                        :disabled="!isEditMode"
                    >
                        <option value="">선택</option>
                        <option v-for="repeat in repeatTypeList" :key="repeat.key" :value="repeat.key">
                            {{ repeat.value }}
                        </option>
                    </select>
                </div>
                <div class="col-4">
                    <label for="repeatInterval">반복 시간</label>
                    <select
                        v-model="eventDetail.repeatInterval"
                        class="form-control"
                        id="repeatInterval"
                        :disabled="!isEditMode"
                    >
                        <option :value="null">선택</option>
                        <option v-for="interval in intervalList" :key="interval.key" :value="interval.key">
                            {{ interval.value }}
                        </option>
                    </select>
                </div>
                <div class="col-4">
                    <label for="">반복 종료 시간</label>
                    <date-picker
                        v-model="eventDetail.endHour"
                        placeholder="반복 종료 시간"
                        vertical
                        :config="{ format: 'HH:mm', locale: 'ko' }"
                        :disabled="!isEditMode"
                    ></date-picker>
                </div>
            </div>
        </div>
        <div v-if="!isEmpty(property)" style="border: 1px solid #ececec; border-radius: 5px;" class="mt-3">
            <div class="row flex-grow-0" style="padding: 1rem 1rem 1rem 1rem;">
                <div class="col-12">
                    <span style="font-size: 14px; font-weight: bold;">제어 설정</span>
                </div>
                <div class="col-12 mt-3">
                    <div v-for="(prop, index) in property" :key="index">
                        <div v-if="prop.encodeType == 'Enum' && prop.encodeDesc" class="mb-3">
                            <b-input-group>
                                <b-input-group-prepend class="prepend-style">
                                    <span>{{ prop.propName }}</span>
                                </b-input-group-prepend>
                                <b-input-group-append>
                                    <b-button
                                        v-for="(item, idx) in JSON.parse(prop.encodeDesc)"
                                        :key="idx"
                                        class="btn"
                                        :class="activeButtons[index] === idx ? 'btn-warning' : 'btn-light'"
                                        @click="setActiveButton(index, idx, idx)"
                                        :disabled="!isEditMode"
                                    >
                                        <trans>{{ item }}</trans>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div
                            v-else-if="(prop.encodeType == 'Range' || prop.encodeType == 'Number') && prop.encodeDesc"
                            class="mb-3"
                        >
                            <b-input-group>
                                <b-input-group-prepend class="prepend-style">
                                    <span>{{ prop.propName }}</span>
                                </b-input-group-prepend>
                                <b-form-input
                                    type="range"
                                    v-model="propValue[index]"
                                    :id="prop.propCode"
                                    :min="JSON.parse(prop.encodeDesc).min"
                                    :max="JSON.parse(prop.encodeDesc).max"
                                    :step="JSON.parse(prop.encodeDesc).offset"
                                    :disabled="!isEditMode"
                                ></b-form-input>
                                <b-input-group-append class="append-style">
                                    <span>{{
                                        propValue[index]
                                            ? propValue[index]
                                            : (Number(JSON.parse(prop.encodeDesc).min) +
                                                  Number(JSON.parse(prop.encodeDesc).max)) /
                                              2
                                    }}</span>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div v-else class="mb-3">
                            <b-input-group>
                                <b-input-group-prepend class="prepend-style">
                                    <span>{{ prop.propName }}</span>
                                </b-input-group-prepend>
                                <b-form-input
                                    type="range"
                                    v-model="propValue[index]"
                                    min="16"
                                    max="30"
                                    step="0.5"
                                    :disabled="!isEditMode"
                                ></b-form-input>
                                <b-input-group-append class="append-style">
                                    <span>{{
                                        propValue[index]
                                            ? propValue[index]
                                            : (Number(JSON.parse(prop.encodeDesc).min) +
                                                  Number(JSON.parse(prop.encodeDesc).max)) /
                                              2
                                    }}</span>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";

export default {
    props: ["events", "mode"],
    components: {},
    data() {
        return {
            eventDetail: null,
            repeatType: "None",
            repeatTypeList: [
                { key: "None", value: "미지정" },
                { key: "Min", value: "분" },
                { key: "Hour", value: "시간" },
            ],
            intervalList: [],
            equipTypeList: [],
            ctrlModeList: [],
            propertyList: [],
            property: [],
            propValue: [],
            activeButtons: [],
            equipData: null,
            controlSetting: false,
            rules,
            validMessage,
        };
    },
    computed: {
        isEditMode() {
            return this.mode !== "view";
        },
        isNewMode() {
            return this.mode === "new";
        },
    },
    watch: {},
    created() {},
    async mounted() {},
    methods: {
        setActiveButton(groupIndex, buttonIndex, value) {
            this.$set(this.activeButtons, groupIndex, buttonIndex);
            this.$set(this.propValue, groupIndex, value);
        },
        newData() {
            return {
                schdEventName: null,
                schdHour: null,
                endHour: null,
                repeatType: "None",
                repeatInterval: null,
                equipType: null,
                ctrlMode: null,
                ctrlCommand: {},
            };
        },
        async setData(data) {
            if (data) {
                // this.mode = 'view';
                this.eventDetail = data;
                this.equipTypeList = this.$store.state.transEquipType.filter((type) =>
                    this.events.some((event) => event.equipType == type.value)
                );
                await this.getEquipCtrlList();
                if (this.eventDetail.ctrlMode) {
                    await this.getProperty();
                }
            } else {
                this.eventDetail = this.newData();
                await this.getEquipCtrlList();
                this.equipTypeList = this.$store.state.transEquipType;
            }
        },
        getData() {
            return this.eventDetail;
        },
        selectRepeatType() {
            if (this.eventDetail.repeatType == "Min") {
                this.intervalList = [
                    { key: 3, value: "3분" },
                    { key: 5, value: "5분" },
                    { key: 10, value: "10분" },
                    { key: 30, value: "30분" },
                ];
            } else if (this.eventDetail.repeatType == "Hour") {
                this.intervalList = [
                    { key: 1, value: "1시간" },
                    { key: 2, value: "2시간" },
                    { key: 3, value: "3시간" },
                    { key: 4, value: "4시간" },
                    { key: 6, value: "6시간" },
                ];
            } else {
                this.intervalList = [];
            }
        },
        async getEquipCtrlList() {
            if (this.eventDetail.equipType) {
                try {
                    let result = await backEndApi.equipTypes.getEquipType(this.eventDetail.equipType, "Y");
                    if (result.status == 200) {
                        this.equipData = result.data;
                        this.ctrlModeList = result.data.ctrlModes;
                        this.propertyList = result.data.ioProps.filter((prop) => prop.ioType === "Both");
                    } else {
                        this.alertWarning(
                            `${result.data.message}`,
                            "",
                            `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                        );
                    }
                } catch (e) {
                    console.error(e);
                }
            } else {
                this.ctrlModeList = [];
                this.propertyList = [];
            }
        },
        async changeEquipType() {
            this.eventDetail.ctrlMode = "";
            this.property = [];
            if (this.eventDetail.equipType) {
                if (this.isNewMode) await this.getEquipCtrlList();
                this.ctrlModeList = this.equipData.ctrlModes;
                this.propertyList = this.equipData.ioProps.filter((prop) => prop.ioType === "Both");
            } else {
                this.ctrlModeList = [];
                this.propertyList = [];
            }
        },
        getProperty() {
            this.propValue = [];
            this.activeButtons = [];
            if (!this.isEmpty(this.eventDetail.ctrlMode)) {
                const findMode = this.ctrlModeList.find((item) => item.ctrlMode == this.eventDetail.ctrlMode);
                const matchingProps = findMode.outProps
                    .map((outProp) => {
                        return this.propertyList.find((ctrlProp) => ctrlProp.propCode === outProp.propCode);
                    })
                    .filter((element) => element);
                this.property = matchingProps;
            } else {
                this.property = [];
                this.propValue = [];
            }
        },
    },
};
</script>

<style scoped>
.prepend-style {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #999;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: white;
}

.append-style {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #999;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: white;
}
</style>
