<template>
    <vvo v-slot="v">
        <div class="row" style="height: calc(100% - 40px);">
            <div class="col-md-12">
                <TableList
                    ref="table-list"
                    :title="''"
                    :columns="columns"
                    :rows="detailInfo.events"
                    :keyField="'schdEventIdx'"
                    :fixedHeader="false"
                    :useFilter="true"
                    :transCodes="transCodes"
                    :controls="controls"
                    :mode="mode"
                    :useMaxHeight="true"
                    :isSort="false"
                    @row-click="handleListItemClick"
                    @button-click="handleListButtonClick"
                />
            </div>
            <b-modal ref="ScheduleSettingDetailModal" centered @hidden="closeDetailModal">
                <template #modal-header>
                    <h5>{{ eventIdx !== null || undefined ? "스케줄 설정 편집" : "스케줄 설정 추가" }}</h5>
                </template>
                <template #modal-footer centered>
                    <div style="display: flex; flex: 1;">
                        <!-- @click="handleAddListItem" -->
                        <button
                            v-show="isEditMode"
                            type="button"
                            class="btn btn-primary"
                            :style="{ marginRight: isEditMode ? '0.5rem' : '' }"
                            style="flex: 1;"
                            @click="onValidate(v, handleAddListItem)"
                        >
                            {{ $t("저장") }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary"
                            :style="{ marginLeft: isEditMode ? '0.5rem' : '' }"
                            style="flex: 1;"
                            @click="closeDetailModal"
                        >
                            {{ $t("닫기") }}
                        </button>
                    </div>
                </template>
                <ScheduleSettingDetailPanel ref="ScheduleSettingDetailPanel" :events="detailInfo.events" :mode="mode" />
            </b-modal>
        </div>
    </vvo>
</template>

<script>
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";
import ScheduleSettingDetailPanel from "./ScheduleSettingDetailPanel.vue";
// import moment from 'moment'
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    props: ["detailInfo", "mode"],
    components: {
        TableList,
        ScheduleSettingDetailPanel,
    },
    data() {
        return {
            eventIdx: null,
            controls: [
                { event: "delete", label: "-", class: "btn-danger" },
                { event: "regist", label: "+", class: "btn-primary" },
            ],
            transCodes: [
                {
                    field: "equipType",
                    codes: this.$store.state.transEquipType,
                },
            ],
            columns: [
                {
                    label: this.$t("이벤트명"),
                    field: "schdEventName",
                    // field: "rowTitle",
                    type: "text",
                    // compositionFields: ['schdEventName', 'schdEventIdx'],
                },
                {
                    label: this.$t("제어 시간"),
                    field: "schdHour",
                    width: "80px",
                    type: "text",
                },
                {
                    label: this.$t("제어 유형"),
                    field: "equipType",
                    type: "text",
                },
                {
                    label: this.$t("제어 모드"),
                    field: "ctrlMode",
                    type: "text",
                },
                {
                    label: this.$t("제어 설정"),
                    field: "ctrlCommand",
                    type: "text",
                },
                {
                    label: this.$t("변경 일자"),
                    field: "regDate",
                    width: "140px",
                    type: "text",
                },
            ],
            popupMessages,
        };
    },
    computed: {
        isEditMode() {
            return this.mode !== "view";
        },
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
        async handleListButtonClick(event, options) {
            const that = this;
            switch (event) {
                case "regist":
                    await that.registNew();
                    break;

                case "delete":
                    await that.deleteChecked();
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        async handleListItemClick(row, index, toggle, check) {
            if (toggle || check) return;
            this.eventIdx = index;
            if (this.mode == "new") this.$emit("editMode");
            let findIoProp = this.detailInfo.events.find((item) => item.schdEventIdx == row.schdEventIdx);
            await this.$refs.ScheduleSettingDetailModal.show();
            await this.$refs.ScheduleSettingDetailPanel.setData(findIoProp);
        },
        async registNew() {
            this.eventIdx = null;
            this.$emit("newMode");
            this.$refs.ScheduleSettingDetailModal.show();
            this.$nextTick(() => {
                this.$refs.ScheduleSettingDetailPanel.setData(null);
            });
        },
        async deleteChecked() {
            try {
                const checked = this.$refs["table-list"].getCheckedRows();

                if (!this.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
                    if (!confirm.value) return;
                    checked.forEach(async () => {
                        this.detailInfo.events = this.detailInfo.events.filter((event) => {
                            return !checked.find((checkedEvent) => checkedEvent.schdEventIdx === event.schdEventIdx);
                        });
                    });
                } else {
                    this.alertNoti(popupMessages.COMMON_REMOVE_NO_DATA_POPUP_MESSAGE);
                }
            } catch (e) {
                this.alertNoti(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE);
                console.error(e);
            }
        },
        async handleAddListItem() {
            const event = this.$refs.ScheduleSettingDetailPanel.getData();

            const property = this.$refs.ScheduleSettingDetailPanel.property;
            const propValue = this.$refs.ScheduleSettingDetailPanel.propValue;

            if (!this.isEmpty(event)) {
                if (this.eventIdx !== null || undefined) {
                    property.forEach((prop, index) => {
                        event.ctrlCommand[prop.propCode] = propValue[index];
                    });
                    this.$set(this.detailInfo.events, this.eventIdx, event);
                    this.eventIdx = null;
                } else {
                    let check = this.detailInfo.events.find((item) => item.schdEventName === event.schdEventName);
                    if (check) {
                        // TODO: Alert Message
                        console.error("TODO: Alert Message");
                    } else {
                        property.forEach((prop, index) => {
                            event.ctrlCommand[prop.propCode] = propValue[index];
                        });
                        await this.$set(this.detailInfo.events, this.detailInfo.events.length, event);
                        // await this.detailInfo.events.sort((a, b) => new Date(a.schdHour) - new Date(b.schdHour))
                    }
                }
            }

            await this.closeDetailModal();
        },
        closeDetailModal() {
            if (this.eventIdx !== null || undefined) this.eventIdx = null;
            this.$refs.ScheduleSettingDetailModal.hide();
        },
    },
};
</script>

<style scoped></style>
