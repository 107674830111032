<template>
    <div class="row m-0">
        <div class="col-7 pl-0 mt-2">
            <div class="col-12 p-0">
                <div class="row justify-content-between align-items-center m-0">
                    <span class="col-6 p-0">제어 이벤트 실행 이력</span>
                    <date-picker
                        v-model="searchDate"
                        class="form-control col-6"
                        placeholder="날짜 선택"
                        :config="{ format: 'YYYY-MM-DD', locale: 'ko', viewMode: 'days' }"
                        @dp-change="getSchdEventHistList(searchDate)"
                    >
                    </date-picker>
                </div>
            </div>
            <!-- @row-click="handleListItemClick"  -->
            <TableList
                ref="table-list"
                :title="''"
                :columns="historyColumns"
                :rows="historyList"
                :fixedHeader="false"
                :useFilter="false"
                :transCodes="transCodes"
                :keyField="'eventExecIdx'"
                :mode="'view'"
                :isSort="false"
                :useMaxHeight="true"
                @row-click="handleHistoryListItemClick"
            />
        </div>
        <div class="col-5 pr-0">
            <div class="col-12 p-0 mt-3">
                <span>제어 처리 결과</span>
            </div>
            <div class="col-12 p-0 mt-2">
                <!-- @row-click="handleListItemClick"  -->
                <TableList
                    ref="table-list"
                    :title="''"
                    :columns="logColumns"
                    :rows="logList"
                    :fixedHeader="false"
                    :useFilter="false"
                    :transCodes="transCodes"
                    :keyField="'outputIdx'"
                    :mode="'view'"
                    :isSort="false"
                    :useMaxHeight="true"
                    @row-click="handleLogListItemClick"
                />
            </div>
        </div>
        <b-modal ref="HistoryLogDetailModal" size="lg" centered @hidden="closeLogDetailModal">
            <template #modal-header>
                <h5>제어 로그 상세</h5>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button type="button" class="btn btn-secondary" style="flex: 1;" @click="closeLogDetailModal">
                        {{ $t("닫기") }}
                    </button>
                </div>
            </template>
            <HistoryLogDetailPanel v-if="logDetail" ref="HistoryLogDetailPanel" :detail="logDetail" />
        </b-modal>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import moment from "moment";
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";
import HistoryLogDetailPanel from "./HistoryLogDetailPanel.vue";

export default {
    props: ["detailInfo", "historyList", "mode", "tabValue"],
    components: {
        TableList,
        HistoryLogDetailPanel,
    },
    data() {
        return {
            transCodes: [
                {
                    field: "equipType",
                    codes: this.$store.state.transEquipType,
                },
            ],
            historyColumns: [
                {
                    label: this.$t("실행 시간"),
                    field: "eventDate",
                    type: "text",
                },
                {
                    label: this.$t("이벤트명"),
                    field: "schdEventName",
                    type: "text",
                    // compositionFields: ['schdEventName', 'eventExecIdx'],
                },
                {
                    label: this.$t("설비 유형"),
                    field: "equipType",
                    width: "70px",
                    type: "text",
                },
                {
                    label: this.$t("성공"),
                    field: "successCnt",
                    type: "text",
                },
                {
                    label: this.$t("실패"),
                    field: "failCnt",
                    type: "text",
                },
            ],
            logColumns: [
                {
                    label: this.$t("처리시간"),
                    field: "formatLogDate",
                    type: "text",
                },
                {
                    label: this.$t("설비명"),
                    field: "equipName",
                    width: "70px",
                    type: "text",
                },
                {
                    label: this.$t("결과"),
                    field: "resultCode",
                    type: "text",
                },
            ],
            equipList: [],
            logList: [],
            logDetail: null,
            searchDate: moment(new Date()).format("YYYY-MM-DD"),
        };
    },
    computed: {},
    watch: {
        "detailInfo.schdCtrlIdx"(newVal) {
            if (!this.isEmpty(newVal)) {
                this.logList = [];
            }
        },
        searchDate() {
            //날짜가 바뀌면 로그를 초기화함
            this.logList = [];
        },
    },
    async created() {
        await this.getEquipList();
    },
    mounted() {
        console.log("ControlHistoryPanel mounted");
    },
    destroyed() {
        console.log("ControlHistoryPanel destroyed");
    },
    methods: {
        async getSchdEventHistList() {
            await this.$emit("update-search-date", this.searchDate);
            await this.$emit("get-event-list", this.searchDate);
        },
        async handleHistoryListItemClick(row) {
            try {
                let result = await backEndApi.equipControl.getSchdEventLogList(row.eventExecIdx);
                if (result.status == 200) this.logList = result.data;
                if (!this.isEmpty(this.logList)) {
                    this.logList.forEach((item) => {
                        item.formatLogDate = moment(item.logDate).format("YYYY-MM-DD HH:mm:ss");
                        let find = this.equipList.find((equip) => equip.equipIdx == item.equipIdx);
                        item.equipName = find.equipName;
                    });
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },
        async handleLogListItemClick(row) {
            try {
                let result = await backEndApi.equipControl.getSchdEventLogDetail(row.eventExecIdx, row.logDate);
                if (result.status == 200) {
                    this.logDetail = result.data;

                    this.logDetail.setDt = moment(this.logDetail.setDt).format("YYYY-MM-DD HH:mm:ss");
                    this.logDetail.updDt = moment(this.logDetail.updDt).format("YYYY-MM-DD HH:mm:ss");

                    let find = this.logList.find((log) => log.eventExecIdx == row.eventExecIdx);
                    this.logDetail.resultMsg = find.resultMsg;

                    await this.$refs.HistoryLogDetailModal.show();
                    // this.$nextTick(() => {
                    //     this.$refs.ExceptionDayDetailPanel.setData(null);
                    // })
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },
        async getEquipList() {
            try {
                let result = await backEndApi.equipInfo.getEquipList();
                if (result.status == 200) this.equipList = result.data;
                else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },
        closeLogDetailModal() {
            this.$refs.HistoryLogDetailModal.hide();
            this.logDetail = null;
        },
    },
};
</script>

<style scoped></style>
