<template>
    <div>
        <div class="row m-0" style="padding-top: 1rem;">
            <div class="col-6">
                <TableList
                    ref="group-table-list"
                    :title="`설비 그룹 (${groups.length})`"
                    :columns="groupColumns"
                    :rows="groups"
                    :fixedHeader="false"
                    :useFilter="false"
                    :mode="mode"
                    :isSort="false"
                    :useMaxHeight="true"
                    :controls="controls"
                    @button-click="handleGroupButtonClick"
                />
            </div>
            <div class="col-6">
                <TableList
                    ref="equip-table-list"
                    :title="`설비 (${equips.length})`"
                    :columns="equipColumns"
                    :rows="equips"
                    :fixedHeader="false"
                    :useFilter="false"
                    :mode="mode"
                    :keyField="'equipIdx'"
                    :isSort="false"
                    :useMaxHeight="true"
                    :controls="controls"
                    @button-click="handleEquipButtonClick"
                />
            </div>
        </div>
        <b-modal ref="GroupModal" centered @hidden="closeGroupModal">
            <template #modal-header>
                <h5>{{ $t("설비 그룹 추가") }}</h5>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button
                        type="button"
                        class="btn btn-primary"
                        style="margin: 0px 0.5rem 0px 0px; flex: 1;"
                        @click="addGroup"
                    >
                        {{ $t("추가") }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        style="margin: 0px 0px 0px 0.5rem; flex: 1;"
                        @click="closeGroupModal"
                    >
                        {{ $t("닫기") }}
                    </button>
                </div>
            </template>
            <GroupPanel ref="GroupPanel" :type="type" />
        </b-modal>
        <b-modal ref="EquipModal" centered @hidden="closeGroupModal">
            <template #modal-header>
                <h5>{{ $t("설비 추가") }}</h5>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button
                        type="button"
                        class="btn btn-primary"
                        style="margin: 0px 0.5rem 0px 0px; flex: 1;"
                        @click="addEquip"
                    >
                        {{ $t("추가") }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        style="margin: 0px 0px 0px 0.5rem; flex: 1;"
                        @click="closeEquipModal"
                    >
                        {{ $t("닫기") }}
                    </button>
                </div>
            </template>
            <EquipPanel ref="EquipPanel" :type="type" />
        </b-modal>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";
import GroupPanel from "./GroupPanel.vue";
import EquipPanel from "./EquipPanel.vue";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    props: ["detailInfo", "mode", "type", "typeName"],
    components: {
        TableList,
        GroupPanel,
        EquipPanel,
    },
    data() {
        return {
            groupColumns: [
                {
                    label: this.$t("그룹명"),
                    field: "sysNodeName",
                    type: "text",
                },
            ],
            controls: [
                { event: "delete", label: "-", class: "btn-danger" },
                { event: "regist", label: "+", class: "btn-primary" },
            ],
            equipColumns: [
                {
                    label: this.$t("설비명"),
                    field: "equipName",
                    type: "text",
                },
            ],
            groups: [],
            groupChecked: [],
            equips: [],
            equipChecked: [],
            equipList: [],
            groupList: [],
            popupMessages,
        };
    },
    computed: {
        isEditMode: {
            get() {
                return this.mode === "edit" || this.mode === "new";
            },
            set(value) {
                this.mode = value ? "edit" : "view";
            },
        },
        isNewMode() {
            return this.mode === "new";
        },
        isViewMode() {
            return this.mode === "view";
        },
    },
    watch: {},
    async created() {
        await this.getEquipList(this.type);
        await this.getGroupNodeList();
        await this.setData();
    },
    beforeDestroy() {
        this.equips = [];
        this.groups = [];
    },
    async mounted() {},
    methods: {
        async getGroupNodeList() {
            const searchParams = {
                nodeType: "Group",
            };
            try {
                let result = await backEndApi.sysNode.getSysNodeList(searchParams);
                if (result.status == 200) {
                    result.data.forEach((item) => {
                        this.groupList.push({
                            sysNodeIdx: item.sysNodeIdx,
                            sysNodeName: item.sysNodeName,
                            equipType: item.equipType,
                        });
                    });
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                    this.groupList = [];
                }
            } catch (e) {
                console.error(e);
            }
        },
        async getEquipList(type) {
            try {
                let result = await backEndApi.equips.getEquipList(type);
                if (result.status == 200) {
                    result.data.forEach((item) => {
                        this.equipList.push({
                            equipIdx: item.equipIdx,
                            equipType: item.equipType,
                            equipName: item.equipName,
                        });
                    });
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                    this.equipList = [];
                }
            } catch (e) {
                console.error(e);
            }
        },
        setData() {
            this.groups = [];
            this.equips = [];
            let find = this.detailInfo.targets.find((item) => item.equipType == this.type);
            if (find.schdCtrlIdx) {
                this.groups = this.detailInfo.groups.filter((item) => item.equipType == this.type);
                this.equips = this.detailInfo.equips.filter((item) => item.equipType == this.type);
                this.equips.forEach((equip) => {
                    let find = this.equipList.find((list) => list.equipIdx == equip.equipIdx);
                    if (find) equip.equipName = find.equipName;
                    else equip.equipName = "";
                });
                this.groups.forEach((group) => {
                    let find = this.groupList.find((list) => list.sysNodeIdx == group.sysNodeIdx);
                    if (find) group.sysNodeName = find.sysNodeName;
                    else group.sysNodeName = "";
                });
            }
        },
        async handleGroupButtonClick(event, options) {
            switch (event) {
                case "regist":
                    await this.showGroupModal();
                    break;

                case "delete":
                    await this.deleteGroupChecked();
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        showGroupModal() {
            this.$refs.GroupModal.show();
        },
        closeGroupModal() {
            this.$refs.GroupModal.hide();
        },
        async deleteGroupChecked() {
            try {
                const checked = this.$refs["group-table-list"].getCheckedRows();
                if (!this.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
                    if (!confirm.value) return;
                    checked.forEach(async () => {
                        this.groups = this.groups.filter((group) => {
                            return !checked.find((checkedEvent) => checkedEvent.groupName === group.groupName);
                        });
                    });
                } else {
                    this.alertNoti(popupMessages.COMMON_DELETE_NOTI_POPUP_MESSAGE);
                }
            } catch (e) {
                this.alertNoti(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE);
                console.error(e);
            }
        },
        async addGroup() {
            let selectGroups = await this.$refs.GroupPanel.groupCheckList();
            if (this.isEmpty(selectGroups)) {
                await this.alertNoti(popupMessages.SCHEDULE_CONTROL_SELECT_GROUP_ADD_NOTI_MESSAGE);
            } else {
                // 기존 그룹과 선택된 그룹을 병합
                const mergedGroups = [
                    ...this.groups,
                    ...selectGroups.map((group) => ({
                        sysNodeIdx: group.sysNodeIdx,
                        equipType: group.equipType,
                        sysNodeName: group.sysNodeName,
                        enabled: "Y",
                    })),
                ];

                const uniqueGroups = Array.from(
                    new Map(mergedGroups.map((group) => [group.sysNodeIdx, group])).values()
                );

                this.groups = uniqueGroups;

                await this.closeGroupModal();
            }
        },
        async handleEquipButtonClick(event, options) {
            switch (event) {
                case "regist":
                    await this.showEquipModal();
                    break;

                case "delete":
                    await this.deleteEquipChecked();
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        showEquipModal() {
            this.$refs.EquipModal.show();
        },
        closeEquipModal() {
            this.$refs.EquipModal.hide();
        },
        async deleteEquipChecked() {
            try {
                const checked = this.$refs["equip-table-list"].getCheckedRows();

                if (!this.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
                    if (!confirm.value) return;
                    checked.forEach(async () => {
                        this.equips = this.equips.filter((equip) => {
                            return !checked.find((checkedEvent) => checkedEvent.equipIdx === equip.equipIdx);
                        });
                    });
                } else {
                    this.alertNoti(popupMessages.COMMON_DELETE_NOTI_POPUP_MESSAGE);
                }
            } catch (e) {
                this.alertNoti(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE);
                console.error(e);
            }
        },
        async addEquip() {
            let selectEquips = this.$refs.EquipPanel.equipCheckList();
            if (this.isEmpty(selectEquips)) {
                await this.alertNoti(popupMessages.SCHEDULE_CONTROL_SELECT_EQUIP_ADD_NOTI_MESSAGE);
            } else {
                selectEquips.forEach((equip) => {
                    this.equips.push({
                        equipIdx: equip.equipIdx,
                        equipType: equip.equipType,
                        equipName: equip.equipName,
                        enabled: "Y",
                    });
                });
                await this.closeEquipModal();
            }
        },
        clearData() {
            this.equips = [];
            this.groups = [];
        },
    },
};
</script>

<style scoped>
.equip-type-ctn {
    height: 200px;
    overflow-y: auto;
    border: 1px solid #d5dbe0;
    border-radius: 5px;
    padding: 10px;
}
</style>
